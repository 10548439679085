import React from "react";
import Header from "./header";
import Footer from "./footer";
import Sev from "./sev";
import { Helmet } from 'react-helmet';
export default function Home() {
    return (
    <>
    <Helmet>
        <title>Famous Vastu Consultant Craigieburn | Vastu Expert Near Me</title>
        <meta name="description" content="Seeking for a famous vastu consultant Craigieburn? Our experts provide vastu consultant services, ensuring your home/office aligns with cosmic energies for prosperity and harmony." />
    </Helmet>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  <section
    id="home"
    className="ds section-home s-py-40 s-pb-lg-80 s-pt-xl-150 s-pb-xl-140"
  >
    <div className="container">
      <div className="divider-50 divider-xl-65" />
      <div className="row align-center">
        <div className="col-lg-6 col-xl-5 order-lg-1 order-2 text-center text-md-left">
          <div className="divider-60 divider-lg-0" />
          <h5 className="special-heading bold mb-2 text-white">
            <span>Sri Vastu Solution</span>
          </h5>
          <p>
          At Sri Vastu Solution, we believe that your home is more than just a place to live—it's a sanctuary of peace and positivity. Our expert Vastu consultation services are designed to align your living spaces with the ancient principles of Vastu Shastra, bringing harmony, health, and prosperity into your life. With personalized solutions tailored to your unique needs, Sri Vastu Solution transforms your home into a haven of balance and well-being. Let us help you create a space where energy flows freely and happiness thrives.
            {/*O Absolute Conscious Lord of the building, having Earth as your seat of creation; I bow to you from my soul, may you always bless my home with money, food and prosperity.*/}
          </p>
          <div className="divider-35 divider-xl-55" />
          <div className="line line-left" />
          <div className="divider-40 divider-xl-60" />
          <h3 className="special-heading fw-500 mb-2 color-main2 big-size">
            <span>Improve Your Life</span>
          </h3>
          <h3 className="special-heading fw-500 text-white fs-50">
            <span>With Amarpreet Mutreja</span>
          </h3>
          <div className="divider-35 divider-xl-65" />
          {/* <a href="about.html" class="btn btn-maincolor">Let’s Begin</a> */}
        </div>
        <div className="col-lg-6 col-xl-7 order-lg-2 order-1 text-center text-lg-right">
          <img
            className="horoscope-image2"
            src=" assets/img/about/svs_amarpreet2.webp"
            alt="img"
          />
        </div>
      </div>
    </div>
  </section>
  <section id="about" className="ds s-py-40 s-py-xl-150 c-gutter-50">
    <div className="container">
      <div className="row align-center">
        <div className="col-lg-6">
          <img
            src=" assets/img/about/svs_amarpreet.webp"
            alt="img"
          />
        </div>
        <div className="col-lg-6 text-center text-md-left">
          <div className="divider-60 divider-lg-0 " />
          <p className="text-white text-center text-md-left fs-20 line-right mb-0">
            <span>About Us</span>
          </p>
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>Sri Vastu Solutions</span>
          </h3>
          <div className="divider-35" />
          <p>
          Sri Vastu Solutions have Vastu Consultants, Astrologers, Feng -Shui and Numerologists in Australia based in Melbourne. They will provide the best
            Tips for home vastu, Office Vastu and new land Vastu also provide
            consultation on{" "}
            <a
              href="https://api.whatsapp.com/send/?phone=61425404494&text=We%20find%20your%20number%20from%20SRI%20VAST%20SOLUTIONS%20WEBSITE.&app_absent=0"
              className="fab fa-whatsapp"
              title="WhatsApp"
              target="_blank"
              rel="noreferrer"
            >
              WhatsApp
            </a>
            . Chat on WhatsApp for Book consultation of Vastu, Fung-sui and
            Numerology.
          </p>
          <p>
            Availability of vastu specialist in Australia may not be easier, by
            knowing this, few residents learnt vastu shastra by reading some
            vastu books, and reading content from vastu websites, etc and doing
            Vastu corrections.
            <br />
            Most of the residents getting negative results as they do not have
            any accessibility of vastu experts in Australia. Sri Vastu Solutions we provide required information for the residents of
            Australia like in Sydney, Canberra, Melbourne, Perth, Adelaide, New
            Zealand and Vastu Consultation all over the globe.
          </p>
          <div className="shortcode-simple-counter counter-layout-2">
            <div className="counter_wrap justify-content-md-start">
              <div className="counter-wrap">
                <h2
                  className="counter counter-size"
                  data-from={0}
                  data-to={15}
                  data-speed={1000}
                >
                  15
                </h2>
              </div>
              <p className="counter-text ">
                <span className="counter-add">
                  Years Of
                  <br />
                  <span className="color-dark fw-500">Experience</span>
                </span>
              </p>
            </div>
          </div>
          <div className="divider-30 divider-xl-50" />
          <a
            href="/contact"
            className="btn btn-outline-maincolor btn-medium"
          >
            Book Now
          </a>
        </div>
      </div>
    </div>
  </section>
  <section id="about" className="ds s-py-40 s-py-xl-150 c-gutter-50">
    <div className="container">
      <div className="row align-center">
        <div className="col-lg-6 text-center text-md-left">
          <div className="divider-60 divider-lg-0 " />
          <p className="text-white text-center text-md-left fs-20 line-right mb-0">
            <span>Improve Your Life- With Amarpreet Mutreja</span>
          </p>
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>PRAYER</span>
          </h3>
          {/*<div class="divider-35"></div>*/}
          <p>
            O Absolute Conscious Lord of the building, having Earth as your seat
            of creation; I bow to you from my soul, may you always bless my home
            with money, food and prosperity.
          </p>
          <div className="divider-30 divider-xl-50" />
          <a
            href="/contact"
            className="btn btn-outline-maincolor btn-medium"
          >
            Book Now
          </a>
        </div>
        <div className="col-lg-6">
          <img src=" assets/img/prayer.jpg" alt="img" />
        </div>
      </div>
    </div>
  </section>
  {/*Section Service*/}
  <Sev/>
  <section id="faq" className="ds s-py-40 s-pt-xl-145 s-pb-xl-130">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h3 className="text-center mt-0 mb-2 special-heading fs-60 color-main">
            <span>What Clients Are Saying</span>
          </h3>
          <div className="line" />
          <p className="text-center fs-20 mt-4">
            <span>
              Many residents are looking for the easy <br />
              Vastu solutions for homes, some residents are looking for Vastu
              solutions.
            </span>
          </p>
          <div className="divider-40 divider-xl-60" />
          <div
            className="owl-carousel quote-carousel owl-navigation"
            data-responsive-lg={3}
            data-responsive-md={3}
            data-responsive-sm={1}
            data-responsive-xs={1}
            data-nav="false"
            data-margin={50}
            data-loop="true"
            data-dots="false"
            data-autoplay="true"
            data-center="true"
          >
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src=" assets/images/gallery/quote_1.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment5.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Sona</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                    I availed Amarpreet Ji’s Vastu guidance for both personal and professional reasons. She has in-depth knowledge of Vastu which give immediate results The corrections carried out by Amarpreet Ji has not only helped us come out of tense situations, also me and my family experienced a lot of positive change in our lives. Her professional acumen has made things look simpler. The remedies are decisive and have a long-lasting effect. Sri Vastu Solutions team’s timely intervention has been of immense help to us. Strongly recommended.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src=" assets/images/gallery/quote_1.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment5.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Harpreet Panchi</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                    I was looking for a good Vastu consultant, but my search ended when I heard about Sri Vastu Solutions. I was referred by my very close friend, who mentioned how his life has totally changed since he consulted Amarpreet and started following the tips and applying the remedies suggested for the correction of the Vastu Dosha in his house. 
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src=" assets/images/gallery/quote_1.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment5.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Samaira Ahuja</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                    Amarpreet has been fantastic to deal with. She has extensive knowledge of Vaastu. Her remedies are highly effective and have proven results. She is also great with follow up and ensures her clients get the best service.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src=" assets/images/gallery/quote_1.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment5.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Saurav Nabh</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                    I really appreciated Sri vastu solutions for the great and reliable service. Thanks so much for help me.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src=" assets/images/gallery/quote_1.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment5.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Punit Raithatha</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                    Amarpreet Ji is very thorough and knowledgeable. She knows the Vaastu art very well. Highly recommend.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src=" assets/images/gallery/quote_2.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment6.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Gandham Mallik</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                    I was searching for a sensible , professional and friendly Vastu consultant and I'm glad I found SRI VASTU SOLUTIONS.  Amarpreet Ji helped me choose a right block of land and house plan according to Vastu. I strongly recommend SRI VASTU SOLUTIONS for Vastu solutions. 
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src="assets/images/gallery/quote_3.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment7.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Jorawar Walia</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                    Amarpreet is highly knowledgeable. Her remedies are highly effective and have proven results. She is very patient and answers every query. Amar is absolutely wonderful to deal with.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

        <Footer/>
        </div>
        </div>
    </>
    )}