import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from 'react-helmet';
import Sev from "./sev";
export default function Astrology() {
    return (
    <>
    <Helmet>
        <title>Best Astrologers in Craigieburn | Astrology and Vastu Consultant</title>
        <meta name="description" content="Achieve cosmic harmony with our best astrology and vastu consultant. Our experts offer personalized astrological readings and vastu solutions for a balanced and prosperous life." />
    </Helmet>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  {/*Section Top*/}
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Vastu Services</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/service">Services</a>
            </li>
            <li className="breadcrumb-item active">Astrology Services</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="vertical-item bg-darkblue text-center content-padding padding-big">
            <div className="item-media">
              <img
                src="assets/img/astrology.jpg?>"
                style={{ borderRadius: "20%" }}
                alt="Sri Vastu Solutions"
              />
            </div>
            <div className="item-content max-content">
              <h2>Astrology Services</h2>
              <p>
                Astrology is a method of predicting mundane events based upon
                the assumption that the celestial bodies—particularly the
                planets and the stars considered in their arbitrary combinations
                or configurations (called constellations)—in some way either
                determine or indicate changes in the sublunar world. The
                theoretical basis for this assumption lies historically in
                Hellenistic philosophy and radically distinguishes astrology
                from the celestial omina (“omens”) that were first categorized
                and cataloged in ancient Mesopotamia. Originally, astrologers
                presupposed a geocentric universe in which the “planets”
                (including the Sun and Moon) revolve in orbits whose centres are
                at or near the centre of the Earth and in which the stars are
                fixed upon a sphere with a finite radius whose centre is also
                the centre of the Earth.
              </p>
              {/* <p>
										Special relations were believed to exist between particular celestial bodies and their varied motions, configurations with each other, and the processes of generation and decay apparent in the world of fire, air, water, and earth. These relations were sometimes regarded as so complex that no human mind could completely grasp them; thus, the astrologer might be readily excused for any errors. 
									</p>
									<p>
										The original purpose of astrology, on the other hand, was to inform the individual of the course of his life on the basis of the positions of the planets and of the zodiacal signs (the 12 astrological constellations) at the moment of his birth or conception. From this science, called genethlialogy (casting nativities), were developed the fundamental techniques of astrology. The main subdivisions of astrology that developed after genethlialogy are general, catarchic, and interrogatory.
									</p> */}
            </div>
          </div>
          <div className="share_buttons social_part">
            <a
              href="https://www.facebook.com/srivastusolution"
              className=" color-bg-icon fa fa-facebook"
              title="facebook"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://twitter.com/SriVastu_AP"
              className="color-bg-icon fa fa-twitter"
              title="twitter"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.instagram.com/srivastusolutions"
              className="color-bg-icon fa fa-instagram"
              title="instagram"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.youtube.com/"
              className="color-bg-icon fa fa-youtube-play"
              title="youtube"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
        {/* .col-* */}
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row align-center">
        <div className="col-lg-6 text-center text-md-left">
          <div className="divider-60 divider-lg-0 " />
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>Zodiac Signs and Astrology Signs </span>
          </h3>
          <div className="divider-35" />
          <p>
            There are 12 zodiac signs, and each sign has its own strengths and
            weaknesses, its own specific traits, desires and attitude towards
            life and people. By analyzing the projection of the position of
            planets, and the Sun and the Moon on the Ecliptic at the moment of
            birth. Astrology can give us a glimpse of a person's basic
            characteristics, preferences, flaws and fears.
          </p>
        </div>
        <div className="col-lg-6">
          <img
            src="/assets/img/Signs-of-the-Zodiac-astrology.jpg"
            style={{ borderRadius: "20%" }}
            alt="16 Vastu zones"
          />
        </div>
        <div className="col-md-12 text-center text-md-left">
          <ul>
            <li>
              {" "}
              <b>Aries</b> March 21 - April 19
            </li>
            <li>
              {" "}
              <b>Taurus</b> April 20 - May 20
            </li>
            <li>
              <b>Gemini</b> May 21 - June 20
            </li>
            <li>
              <b>Cancer</b> June 21 - July 22
            </li>
            <li>
              <b>Leo</b> July 23 - August 22
            </li>
            <li>
              <b>Virgo</b> August 23 - September 22
            </li>
            <li>
              <b>Libra</b> September 23 - October 22
            </li>
            <li>
              <b>Scorpio</b> October 23 - November 21
            </li>
            <li>
              <b>Sagittarius</b> November 22 - December 21
            </li>
            <li>
              <b>Capricorn</b> December 22 - January 19
            </li>
            <li>
              <b>Aquarius</b> January 20 - February 18
            </li>
            <li>
              <b>Pisces</b> February 19 - March 20
            </li>
          </ul>
          <p>
            Each of the 12 horoscope signs belongs to one of the four elements –
            Air, Fire, Water and Earth. These elements represent an essential
            type of energy that acts in each of us. Astrology aims to help us
            focus these energies on the positive aspects and to gain a better
            understanding of our potential and our positive traits and deal with
            negative ones.
          </p>
          <p>
            Fire: Aries, Leo, Sagittarius , Earth: Taurus, Virgo, Capricorn ,
            Air: Gemini, Libra, Aquarius , Water: Cancer, Scorpio, Pisces
          </p>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row align-center">
        <div className="col-lg-6">
          <img
            src="assets/img/astrology_planets.jpg"
            style={{ borderRadius: "20%" }}
            alt="img"
          />
        </div>
        <div className="col-lg-6 text-center text-md-left">
          <div className="divider-60 divider-lg-0 " />
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>Planets of Astrology (Navagraha)</span>
          </h3>
          <div className="divider-35" />
          <p>
            As an astrological refresher, all of the planets function as the
            main players in your natal chart, with each one located in one of
            the 12 zodiac signs and one of the 12 houses at the time you were
            born. Going back to cosmic basics, then, it’s key to understand the
            meanings of all the planets (Navagraha) in astrology in order to
            interpret their relative positioning in your chart and what fate
            that might forecast.
          </p>
        </div>
        <div className="col-md-12 text-center text-md-left">
          <ul>
            <li>
              {" "}
              <b>The Sun (Surya) </b> <br />
              Surya, the God of the Sun is considered to be the leader amongst
              the Navagraha. Rightly so, as the other members of the celestial
              group revolve around Surya.he sun god is depicted seated on a
              chariot pulled by 7 horses, which is a symbolic way of
              representing the splitting of white sunlight into seven colours of
              the VIBGYOR spectrum. Surya as a Navagraha embodies qualities such
              as leadership, ego, strength, authority and vitality of a person.
            </li>
            <li>
              {" "}
              <b> The Moon (Chandra) </b> <br />
              he lunar deity is never depicted with a full body, symbolising his
              waxing and waning and his chariot is said to be pulled by
              antelopes. Monday is the day associated with Chandra, and one must
              offer white or silver coloured articles and wear white to please
              the celestial god. The moon is said to embody thinking, stability
              and fertility.{" "}
            </li>
            <li>
              {" "}
              <b> Mars (Mangal) </b> <br />
              Mangal, is the astrological ruler of the red planet, Mars. The
              theme of colour red is reflected in Mangal’s iconography, as he is
              often depicted to have red skin. Mangal’s vahana is a ram and the
              deity is said to have an astrological influence on skills of
              hands, handling of equipment, anger and strength. Articles like
              wheat, red cloth, red flowers, copper and sandalwood are offered
              to the deity during prayers. Tuesday or Mangalvaar is the day
              associated with the celestial deity.
            </li>
            <li>
              {" "}
              <b>Mercury (Budh)</b> <br />
              Budh, is the Navagraha deity that rules the planet which is
              closest to the sun in the solar system, Mercury. Budh, as a
              Navagraha, influences intellect, communication, humour and
              reasoning, and Wednesday, or Budhwar is the day allocated to the
              deity. One is advised to offer articles like, green cloth and
              moong lentils to please the astrological deity.
            </li>
            <li>
              {" "}
              <b> Jupiter: luck (Brihaspati) </b> <br />
              The sage Brihaspati is the Navagraha deity representing the giant
              planet Jupiter. He is a wise god who is considered to be the lord
              of sacred speech. The celestial god is said to embody qualities
              like spirituality, wisdom, dignity, morality, and luck. The golden
              complexioned god is offered articles that are yellow in colour,
              like gram pulse or flour, yellow flowers and turmeric.
            </li>
            <li>
              {" "}
              <b> Venus: love and relationships (Shukra) </b> <br />
              Shukra , is the deity of the planet Venus. Friday or Shukravaar is
              the planetary day of the deity, and the colour white is associated
              with him. Articles like rice, ghee, camphor, curd, sugar are
              offered to the deity. Shukra embodies qualities like, material
              desires, relationships, creativity, aesthetics and healing.{" "}
            </li>
            <li>
              {" "}
              <b> Saturn (Shani) </b> <br />
              Shani, is the Hindu god of justice and the ruler of the planet
              Saturn. The powerful god keeps a record of karmic deeds of humans
              and passes his judgements upon them during their lifetime.
              Saturday or Shanivaar is the day associated with the celestial god
              of judgement. One is advised to offer black iron nails, black
              lentils and black clothes to Shani.
            </li>
            <li>
              {" "}
              <b> Rahu </b> <br />
              Rahu is the decapitated head of the asura, Svarbhanu, and it
              represents the northern lunar node. Rahu is responsible for the
              eclipse of the sun. Since the deity lacks a body people believe
              that the influence of this deity causes undue harm. People donate
              mustard, saffron, sesame, lead and coal to appease Rahu.
            </li>
            <li>
              {" "}
              <b> Ketu </b> <br />
              Ketu is the headless body of the asura, Svarbhanu, and it
              represents the southern lunar node. Ketu is responsible for the
              eclipse of the moon. People believe Ketu represents amorality,
              violence, detachment, confusion, mindlessness, and impulsivity. To
              avoid the ill effects of the celestial god people donate saffron,
              sesame, lead and sugar and offer food to a black and white
              coloured dog.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <Sev/>
</>


        <Footer/>
        </div>
        </div>
    </>
    )}