import React from "react";
import Header from "./header";
import Footer from "./footer";
export default function Vasturemedy() {
    return (
    <>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Vastu Remedy</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active">Vastu Remedy</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-pt-90 s-pt-xl-145 s-pb-60 s-pb-xl-115 container-px-30">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="row isotope-wrapper masonry-layout c-mb-30">
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/redhorse.png"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Red horses</h6>
                  <p>
                    Red horses enables you to optimise the efforts that you put
                    into any work, This effort is then, converted into money and
                    recognition, This remedy infuses a new vigor and vitality to
                    your public relations, and helps you to establish a
                    respectful reputation in your business and social circles.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/whitehorse.png"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">White Horses Statue</h6>
                  <p>
                    This pair of running white horses enables better access to
                    money and ensures its smooth flow in a partnership. In case
                    you are in job and wish to increase your salary, then the
                    pair of white horses is just the remedy you need. Placing
                    this pair of running white horses on the floor in the North
                    West section of your building, you can ensure that the banks
                    and other financial institutions will sanction your loans
                    and meet your other financial requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/kuber.png"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Kuber Brass Statue</h6>
                  <p>
                    Yakshraj Kuber is the lord of wealth and guardian of all the
                    treasures of the universe.He is the wealthiest devta. His
                    primary function as the lord of wealth is the creation of
                    extremly wealthy people. Kuber has the ability to translate
                    your actions into riches and growth, helping you to achieve
                    bliss and abundant wealth. Place Kuber in North on a table
                    or shelf, facing towards South.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row isotope-wrapper masonry-layout c-mb-30">
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/indradevta.png"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Indra Dev Brass</h6>
                  <p>
                    Inder Dev has been considered as the lord of heaven and is
                    responsible for the smooth and orderly functioning of
                    heaven, where he leads a life full of bliss and luxury. A
                    sculpture of Inder Dev in brass material should be placed on
                    a table in the east of the building, facing west.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Cow-Calf.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">
                    Cow &amp; Calf Aesthetic Brass
                  </h6>
                  <p>
                    Superior finish, creativity and an artistic wonder
                    art-piece. Represents and Symbolizes Care, motherhood,
                    strength and divinity. This kamdhenu cow and calf statue
                    aptly conveys the sentiments of commoners who consider cow
                    as God in India.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Laxmi-Ji-on-Singhasan.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Goddess Laxmi</h6>
                  <p>
                    Laxmi hindu religious Idol Statue Goddess brings prosperity,
                    good luck and happiness in your home &amp; Office.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row isotope-wrapper masonry-layout c-mb-30">
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Nandi.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Nandi Brass Statue</h6>
                  <p>
                    Lord Shiva’s trusted steed – The Nandi Bull protects a
                    business against any unforeseen calamity or danger. It is
                    also responsible for forging long and faithful partnerships
                    and collaborations with other supporting businesses and
                    business people.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Pair-of-Love-Birds.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">
                    Pair of Love Birds for Love
                  </h6>
                  <p>
                    Placing this Love birds sculpture in South West section of
                    your building, on the floor or table, facing center of the
                    building, strengthens the love and bond between the husband
                    and wife, family harmony, marriage matters, and skills.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/whitepiramid.png"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">White Pyramid</h6>
                  <p>
                    A pyramid works by amplifying the energy of the section by
                    approximately 108 times. Pyramids are very powerful, and
                    must be used with great care.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row isotope-wrapper masonry-layout c-mb-30">
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Brass-Surya-Dev.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Surya Dev Brass</h6>
                  <p>
                    Surya Dev can be used for Vastu, Fengshui, Good luck,
                    decoration, house warming gifts, business gifts etc.
                    Enlighten your home with this auspicious surya wall hanging.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Brass-Panch-Mukhi-Hanuman.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">
                    Panchmukhi Five Faced Hanuman Ji
                  </h6>
                  <p>
                    Hanuman Ji’s prayers helps one overcome bad habits like
                    corruption, adultery, laziness, fickle mindedness,
                    procrastination etc. It brings in confident and stable mind.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Balaji.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">
                    Lord Tirupati Balaji
                  </h6>
                  <p>
                    Ideal gift for all occasions, Diwali Gift- Birthday Gift-
                    Home Decor Present- Welcome Gift- Raksha Bandhan Gift-
                    Goverdhan Pujan Gift- Bhai Duj Gift-Spiritual Lucky Gift.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row isotope-wrapper masonry-layout c-mb-30">
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Radha-Krishna.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">
                    Lord Krishna with Radha
                  </h6>
                  <p>
                    This Krishna Radha Statue is built with Superior finish,
                    creativity and anartistic wonder art-piece. Bring this
                    powerful deity lord’s sculpture at your home &amp; spread
                    the goodness of spiritual power around your house. He is the
                    fearless one.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Brass-Lion.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Lion Brass Statue</h6>
                  <p>
                    Placing a Brass Lion on the floor in the North of North East
                    section of building, facing the center of the building, will
                    strengthen your own personality. You will also begin to
                    exert a stronger influence on society.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Ladoo-Gopal.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">
                    Laddu Gopal Aesthetic
                  </h6>
                  <p>
                    This Ladoo Gopal Statue Brings Prosperity, Wisdom and
                    Happiness. Ideal gift for all occasions, Diwali Gift-
                    Birthday Gift- Home Decor Present- Welcome Gift- Raksha
                    Bandhan Gift- Goverdhan Pujan Gift- Bhai Duj Gift-Spiritual
                    Lucky Gift.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row isotope-wrapper masonry-layout c-mb-30">
            <div className="col-xl-4 col-sm-6" style={{minHeight: 650}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/Kamdhenu.jpg"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Kamdhenu Cow</h6>
                  <p>
                    The Kamdhenu Cow has heavenly powers, and is especially
                    effective in attracting profits from land and agricultrual
                    technology. This remedy helps you to successfully complete
                    projects related to mining, building, constructions and
                    structural design.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-6" style={{minHeight: 600}}>
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/vastu/deer.png"
                    alt="img"
                    style={{height : 467, width : 467}}
                  />
                </div>
                <div className="item-content">
                  <h6 className="mb-2 mt-0 text-center">Deer Brass Statue</h6>
                  <p>
                    Brass Deer Statue helps in removing any form of stress,
                    stagnation or lethargy, and fills you with youthful energy.
                    It works to detoxify your mind of negative thoughts and
                    blocked emotions..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>


        <Footer/>
        </div>
        </div>
    </>
    )}