import React from "react";
import Header from "./header";
import Footer from "./footer";
import Sev from "./sev";
export default function Services() {
    return (
    <>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  {/*Section Top*/}
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Sri Vastu Services</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/services">services</a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  {/*Section Service*/}
  <Sev/>
</>


        <Footer/>
        </div>
        </div>
    </>
    )}