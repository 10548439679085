import React from "react";

export default function Header() {
    return (
        <>
        <div className="header_wrapper ">
  
  <section className="page_topline ds s-py-30 ">
    <div className="container" >
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6 col-md-6 text-lg-left text-center">
          <a href="/" className="logo">
            <img src="assets/images/logo.png" alt="img" />
            <span
              className="logo-text color-darkgrey"
              style={{ letterSpacing: "0.3em" }}
            >
              SRI VASTU SOLUTIONS
            </span>
          </a>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 some-links text-center text-lg-right d-flex justify-content-center justify-content-md-end align-items-center mt-2 mt-md-0">
          {/* <a
            className="fs-24 links-maincolor-hover font-2"
            href="tel:+61425404494"
          >
            <mark>+61</mark>425 404 494
          </a> */}
          <a
            className="fs-24 links-maincolor-hover font-2"
            href="tel:+61422621093"
          >
            
            <mark>+61</mark>422 621 093
          </a>
          <a
            href="/contact"
            className="btn btn-outline-maincolor btn-medium  d-none d-md-block"
          >
            Book Consultancy
          </a>
        </div>
      </div>
    </div>
  </section>
  <header className="page_header ds ms s-overlay justify-nav-start">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-9 col-1 order-2 order-xl-1">
          <div className="nav-wrap">
            
            <nav className="top-nav">
              <ul className="nav sf-menu">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/services">Services</a>
                  <ul>
                    <li>
                      <a href="/vastu">
                        Vastu Services
                      </a>
                    </li>
                    <li>
                      <a href="/astrology">
                        Astrology Services
                      </a>
                    </li>
                    <li>
                      <a href="/numerology">
                        Numerology Services
                      </a>
                    </li>
                    <li>
                      <a href="/fengshui">
                        Feng-Shui Services
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/vasturemedy">Vastu Remedy</a>
                </li>
                <li>
                  <a href="/vastutips">Vastu Tips</a>
                </li>
                <li>
                  <a href="/contact">Contact-Us</a>
                </li>
              </ul>
            </nav>
            {/* eof main nav */}
          </div>
        </div>
        <div className="col-xl-3 col-9 order-1 order-xl-2 text-xl-right text-left">
          <span className="social-icons">
            <a
              className="fa fa-phone"
              title="instagram"
              target="_blank"
              href="tel:+61422621093"
              rel="noreferrer"
            />
            <a
              href="https://www.facebook.com/srivastusolution"
              className="fa fa-facebook"
              title="facebook"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://twitter.com/SriVastu_AP"
              className="fa fa-twitter"
              title="twitter"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.instagram.com/srivastusolutions"
              className="fa fa-instagram"
              title="instagram"
              target="_blank"
              rel="noreferrer"
            />
            {/* <a
              href="https://www.youtube.com/"
              className="fa fa-youtube-play"
              title="youtube"
              target="_blank"
              rel="noreferrer"
            /> */}
            <a
              href="https://api.whatsapp.com/send/?phone=61422621093&text=We%20find%20your%20number%20from%20SRI%20VAST%20SOLUTIONS%20WEBSITE.&app_absent=0"
              className="fab fa-whatsapp"
              title="WhatsApp"
              target="_blank"
              rel="noreferrer"
            />
          </span>
        </div>
      </div>
    </div>
    {/* header toggler */}
    <span className="toggle_menu">
      <span />
    </span>
  </header>
</div>

        </>
    )
}