import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes , Route } from 'react-router-dom';
import { useContext, useState } from 'react';
import Home from './pages/home';
import About from './pages/about';
import Services from './pages/services';
import Astrology from './pages/astrology';
import Contact from './pages/contact';
import Fengshui from './pages/fengshui';
import Numerology from './pages/numerology';
import Vastu from './pages/vastu';
import Vasturemedy from './pages/vasturemedy';
import Vastutips from './pages/vastutips';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/services" element={<Services/>}></Route>
        <Route path='/astrology' element={<Astrology/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/fengshui' element={<Fengshui/>}></Route>
        <Route path='/numerology' element={<Numerology/>}></Route>
        <Route path='/vastu' element={<Vastu/>}></Route>
        <Route path='/vasturemedy' element={<Vasturemedy/>}></Route>
        <Route path='/vastutips' element={<Vastutips/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
