import React from "react";
import Header from "./header";
import Footer from "./footer";
import Sev from "./sev";
export default function About() {
    return (
    <>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  {/*Section Top*/}
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>About Us</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active">About Us</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  {/*Section About*/}
  <section id="about" className="ds s-py-40 s-py-xl-150 c-gutter-50">
    <div className="container">
      <div className="row align-center">
        <div className="col-lg-6">
          <img
            src="assets/img/about/svs_amarpreet.webp"
            alt="img"
          />
        </div>
        <div className="col-lg-6 text-center text-md-left">
          <div className="divider-60 divider-lg-0 " />
          <p className="text-white text-center text-md-left fs-20 line-right mb-0">
            <span>About Us</span>
          </p>
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>Sri Vastu Solutions</span>
          </h3>
          <div className="divider-35" />
          <p>
          Sri Vastu Solutions have Vastu Consultants, Astrologers, Feng -Shui and Numerologists in Australia based in Melbourne. They will provide the best
            Tips for home vastu, Office Vastu and new land Vastu also provide
            consultation on{" "}
            <a
              href="https://api.whatsapp.com/send/?phone=61425404494&text=We%20find%20your%20number%20from%20SRI%20VAST%20SOLUTIONS%20WEBSITE.&app_absent=0"
              className="fab fa-whatsapp"
              title="WhatsApp"
              target="_blank"
              rel="noreferrer"
            >
              WhatsApp
            </a>
            . Chat on WhatsApp for Book consultation of Vastu, Fung-sui and
            Numerology.
          </p>
          <p>
            Availability of vastu specialist in Australia may not be easier, by
            knowing this, few residents learnt vastu shastra by reading some
            vastu books, and reading content from vastu websites, etc and doing
            Vastu corrections.
            <br />
            Most of the residents getting negative results as they do not have
            any accessibility of vastu experts in Australia. Sri Vastu Solutions we provide required information for the residents of
            Australia like in Sydney, Canberra, Melbourne, Perth, Adelaide, New
            Zealand and Vastu Consultation all over the globe.
          </p>
          <div className="shortcode-simple-counter counter-layout-2">
            <div className="counter_wrap justify-content-md-start">
              <div className="counter-wrap">
                <h2
                  className="counter counter-size"
                  data-from={0}
                  data-to={15}
                  data-speed={1000}
                >
                  15
                </h2>
              </div>
              <p className="counter-text ">
                <span className="counter-add">
                  Years Of
                  <br />
                  <span className="color-dark fw-500">Experience</span>
                </span>
              </p>
            </div>
          </div>
          <div className="divider-30 divider-xl-50" />
          <a
            href="/contact"
            className="btn btn-outline-maincolor btn-medium"
          >
            Book Now
          </a>
        </div>
      </div>
    </div>
  </section>
  {/* Section features */}
  <section className="ds bs features-section s-overlay s-overlay-half-left s-py-40 s-py-xl-150 container-px-xl-30">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-12 col-lg-6 col-xl-5 text-center text-lg-left left section-special-2">
          <div className="z-index-2">
            <div>
              <p className="text-white text-center text-md-left fs-20 line-right mb-0">
                <span>Advantages</span>
              </p>
              <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
                <span>
                  Benefits of <br />
                  Vastu Magic
                </span>
              </h3>
              <p className="text-center text-md-left  mt-4">
                <span>
                We provide the most accurate horoscope reading based <br/> on your Astrology Charts and by analysing your zodiac sign
                </span>
              </p>
              <div className="divider-60 divider-lg-0" />
            </div>
          </div>
        </div>
        <div className="col-lg-6 section-special-3">
          <div className="z-index-2 ds opacity">
            <div>
              <div className="media">
                <div className="fs-50">
                  <i className="ico ico-moon" />
                </div>
                <div className="media-body">
                  <h6 className="mt-1 mb-2 color-main2">
                    Acts as a Financial Planner
                  </h6>
                  <p>
                    The planet in the 1st house of your horoscope and its lord
                    influence the skills and talent in a person.
                  </p>
                </div>
              </div>
              <div className="divider-40 divider-xl-60" />
              <div className="media">
                <div className="fs-50">
                  <i className="ico ico-tarot" />
                </div>
                <div className="media-body">
                  <h6 className="mt-1 mb-2 color-main2">
                    Makes You Confident and Optimistic
                  </h6>
                  <p>
                    If you hone the skills you are naturally good at and use it
                    to your benefit, you will not just save your time
                  </p>
                </div>
              </div>
              <div className="divider-40 divider-xl-60" />
              <div className="media">
                <div className="fs-50">
                  <i className="ico ico-gem" />
                </div>
                <div className="media-body">
                  <h6 className="mt-1 mb-2 color-main2">
                    Helps in Decision Making
                  </h6>
                  <p>
                    A person with Ascendant lord Venus will be inclined towards
                    creative, artistic and innovative fields.
                  </p>
                </div>
              </div>
              <div className="divider-40 divider-xl-60" />
              <div className="media">
                <div className="fs-50">
                  <i className="ico ico-diamond" />
                </div>
                <div className="media-body">
                  <h6 className="mt-1 mb-2 color-main2">
                    Tells if Your Partner Is Rich and Attractive
                  </h6>
                  <p>
                    Horoscope not just informs you about your skills but also
                    tells you a specific career which will benefit you the most.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*Section Service*/}
  <Sev/>
  {/* Section Faqs */}
  <section id="faq" className="ds s-py-40 s-pt-xl-145 s-pb-xl-130">
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h3 className="text-center mt-0 mb-2 special-heading fs-60 color-main">
            <span>What Clients Are Saying</span>
          </h3>
          <div className="line" />
          <p className="text-center fs-20 mt-4">
            <span>
              Many residents are looking for the easy <br />
              Vastu solutions for homes, some residents are looking for Vastu
              solutions.
            </span>
          </p>
          <div className="divider-40 divider-xl-60" />
          <div
            className="owl-carousel quote-carousel owl-navigation"
            data-responsive-lg={3}
            data-responsive-md={3}
            data-responsive-sm={1}
            data-responsive-xs={1}
            data-nav="false"
            data-margin={50}
            data-loop="true"
            data-dots="false"
            data-autoplay="true"
            data-center="true"
          >
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src="assets/images/gallery/quote_1.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment5.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Vani</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                      A very good book for those who are new to the subject and
                      want to explore new age vastu. I recommend it for every
                      household, businessman, industrialist or student.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src="assets/images/gallery/quote_2.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment6.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Stephie J. Bellamy</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                      I followed it and found suitable for day by day vastu
                      tips. Based on Sri Vastu Solutions concept.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="quote-item vertical quote-layout-2">
              <div className="wrap-img">
                <img
                  src="assets/images/gallery/quote_3.jpg"
                  alt="img"
                />
              </div>
              <div className="wrap-content">
                {/* <div class="quote-image">
								<img src="assets/images/team/comment7.png" alt="img">
							</div> */}
                <div className="quote-title">
                  <h5 className="mb-0 color-main2 bold">Nitien</h5>
                  <p className="mb-0 text-white">Happy Customer</p>
                </div>
                <div className="quote-content">
                  <blockquote>
                    <p>
                      Bet professional vastu expert services for your business,
                      industries , hospital &amp; hotels from Sri vast
                      Solutions.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

        <Footer/>
        </div>
        </div>
    </>
    )}