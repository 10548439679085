import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from 'react-helmet';
import Sev from "./sev";
export default function Vastu() {
    return (
    <>
    <Helmet>
        <title>House Vastu Consultant Near Me | Vastu for Good Health and Wealth</title>
        <meta name="description" content="Discover the power of vastu for both your home and office. Our skilled consultants provide Vastu for good health and wealth, aligning energies for a harmonious and prosperous environment" />
    </Helmet>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  {/*Section Top*/}
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Vastu Services</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/service">Services</a>
            </li>
            <li className="breadcrumb-item active">Vastu Shastra</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="vertical-item bg-darkblue text-center content-padding padding-big">
            <div className="item-media">
              <img
                src="assets/img/vastu-shastra-5-elements.jpg"
                alt="Sri Vastu Solutions"
              />
            </div>
            <div className="item-content max-content">
              <h2>Vastu Services</h2>
              <p>
                Vastu Shastra are the textual part of Vastu Vidya - the broader
                knowledge about architecture and design theories from ancient
                India. Vastu Vidya is a collection of ideas and concepts, with
                or without the support of layout diagrams, that are not rigid.
                Rather, these ideas and concepts are models for the organisation
                of space and form within a building or collection of buildings,
                based on their functions in relation to each other, their usage
                and the overall fabric of the Vastu. Ancient Vastu Shastra
                principles include those for the design of Mandir (Hindu
                temples), and the principles for the design and layout of
                houses, towns, cities, gardens, roads, water works, shops and
                other public areas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row align-center">
        <div className="col-lg-6">
          <img
            src="assets/img/elements_vastu.jpg"
            style={{ borderRadius: "20%" }}
            alt="img"
          />
        </div>
        <div className="col-lg-6 text-center text-md-left">
          <div className="divider-60 divider-lg-0 " />
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>Elements of Vastu </span>
          </h3>
          <div className="divider-35" />
          <p>
            Throughout the world, the concept of the five elements as the basis
            of all creation was accepted and used in medicine, alchemy and
            astrology. These Five Elements have been considered to be the basis
            of each and every creation.
          </p>
          <p>
            Now, let us understand the Nature, Qualities and the Effects of
            these Five Elements i.e., the Water, Air, Fire, Earth and Space, as
            to what they are individually.
          </p>
        </div>
        <div className="col-md-12 text-center text-md-left">
          <ul>
            <li>
              <b>Water</b>
              <br />
              <p>
                Whatever is liquid, whatever flows and has a particular density,
                whatever has the particular, property of evaporation,
                condensation into vapour and conversion into a liquid again- all
                these are considered to be water. Water is usually blue or black
                in colour. It has a wavy shape. So the shape of Moon &amp; the
                shape of wave is the Water element.
              </p>
              <p>
                Nature: Water has tendency of flowing, thereby having cleansing
                abilities. It represents clarity, flow, generation &amp;
                continuity.
              </p>
            </li>
            <li>
              <b>Air</b>
              <p>
                Air has the property to give breath to life. If something gets
                stuck, growth of some kind is required to move it. However, too
                much of growth is negative. So, it is always controlled by some
                other element. The green and brown colours, the rectangular and
                the oval shapes are all related to the Air or Wood element.
              </p>
              <p>
                Nature: The element of air has property growth and if talk about
                growth, human body needs breathe (air) which comes from trees.
                The trees in itself has the property of growth. They always grow
                vertically. Their stems are oval, therefore, all the matter that
                are oval belongs to air element.
              </p>
            </li>
            <li>
              <b>Fire</b>
              <p>
                Fire is the spark of life-Tejas. Anything that attracts has
                spark, and possesses the qualities of Fire. The Fire element has
                a triangular shape, and so the shape associated with it is a
                triangle. It is also considered octagon in shape, and the
                colours associated with Fire are: Red, Orange and sometimes,
                Purple.
              </p>
              <p>
                Nature: Right from the primitive stages, fire has used to draw
                protection from. It represents the spark, zeal and passion in
                life. Fire is the driving force behind all of life processes,
                and thus is equated to money in the modern era.
              </p>
            </li>
            <li>
              <b>Earth</b>
              <p>
                If anything is stable, it is due to the Earth element. It has a
                yellow colour or the colour of the earth, and is square in
                shape.{" "}
              </p>
              <p>
                Nature: Weight is the property of the Earth element. If we say
                our mere presence carries weight, or our speech carries weight:
                it is denotative of the Earth element. What it really means is
                that it is originating from knowledge or skill, and these give
                true weight (as a valuable weighty item) to a person. So, the
                Earth imparts stability and fixes things in position.
              </p>
            </li>
            <li>
              <b>Space</b>
              <p>
                {" "}
                The Psyche is developed from the Space itself. Therefore,
                without being aware of the Space and Psyche, it's not possible
                to know their effects on human life. Therefore, Space has two
                colours-grey and white. In fact, white has all the colours in it
                but we cannot say that the of white always indicates space. The
                presence 'Space' element is round or spherical in shape.
              </p>
              <p>
                Nature: In vastu, 'Space' refer to something defined by
                boundaries. In context of a building, Space means the built-up
                Space of inside a building. West is the direction of the Space
                element. In China, the element, Space is also known as Metal,
                due to perfect space configuration in the atomic structure of
                metals.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row align-center">
        <div className="col-lg-6 text-center text-md-left">
          <div className="divider-60 divider-lg-0 " />
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>16 Vastu zones </span>
          </h3>
          <div className="divider-35" />
          <p>
            All of us desire to have good health, wealth, harmony, peace and
            prosperity at home. To ensure these, you have to ensure the zones
            are free of anti-elements and activities.
          </p>
          <ul>
            <li>
              <b>North Zone</b> represents money and wealth. This direction is
              governed by Kuber, the god of wealth The energy in this zone helps
              one to start or venture new opportunities to earn and acquire
              monetary growth. The element for this zone is Water, color is
              blue, metal is Aluminium and shape is wavy.
            </li>
            <li>
              <b>North North East (NNE) </b> zone is very important and
              delicate, this is the zone for immunity. The element for this zone
              is Water, color is blue, metal is Aluminium and shape is wavy.
            </li>
            <li>
              <b>North East (NE)</b> This zone is a source of wealth, health and
              success. This zone is ideal for meditation, wisdom, knowledge. The
              element for this zone is Water, color is blue, metal is Aluminium
              and shape is wavy.
            </li>
            <li>
              {" "}
              <b>East North East (ENE) </b> zone governs fun and refreshment.
              Ideal as a family lounge. This is the zone of happiness. The
              element for this zone is Air, color is green, metal is Stainless
              Steel and shape is rectangle.{" "}
            </li>
          </ul>
        </div>
        <div className="col-lg-6">
          <img
            src="assets/img/vastucompass.jpg"
            style={{ borderRadius: "20%" }}
            alt="16 Vastu zones"
          />
        </div>
        <div className="col-md-12 text-center text-md-left">
          <ul>
            <li>
              {" "}
              <b>East (E)</b> This is the zone for networking and socializing.
              The element for this zone is Air, color is green, metal is
              Stainless Steel and shape is rectangle.
            </li>
            <li>
              {" "}
              <b>East South East (ESE) </b> This is the zone of churning and
              overthinking. The element for this zone is Air, color is green,
              metal is Stainless Steel and shape is rectangle.
            </li>
            <li>
              {" "}
              <b>South East (SE)</b> Ideally, the kitchen is construction in the
              South East direction. It is related to cooking and food. Lord Agni
              bestows us with wealth, crops. This is the zone of Fire and Money.
              The element for this zone is Fire, color is Red, metal is Copper
              and shape is Triangle.
            </li>
            <li>
              {" "}
              <b>South South East (SSE) </b> This is the zone of confidence and
              Strength. The element for this zone is Fire, color is Red, metal
              is Copper and shape is Triangle.
            </li>
            <li>
              {" "}
              <b>South (S) </b> This is zone of Fame and relaxation. The element
              for this zone is Fire, color is Red, metal is Copper and shape is
              Triangle.
            </li>
            <li>
              {" "}
              <b>South South West (SSW)</b> This is the zone of disposal. The
              energy in this zone removes all waste and useless things in one’s
              life. The element for this zone is Earth, color is Yellow, metal
              is Brass and shape is Square.{" "}
            </li>
            <li>
              {" "}
              <b>South West (SW) </b> This is the zone for relationships. The
              element for this zone is Earth, color is Yellow, metal is Brass
              and shape is Square.{" "}
            </li>
            <li>
              {" "}
              <b>West South West (WSW)</b> This zone is for studies and
              excellence in academics/education. Practice studies in this zone
              to attain good knowledge and education. The element for this zone
              is Air, color is White, metal is Iron and shape is Circle.{" "}
            </li>
            <li>
              {" "}
              <b> West (W) </b> This the zone of Gain. The element for this zone
              is Air, color is White, metal is Iron and shape is Circle.
            </li>
            <li>
              {" "}
              <b>West North West (WNW) </b> The energy in this zone brings in
              stress and depression. The element for this zone is Air, color is
              White, metal is Iron and shape is Circle.
            </li>
            <li>
              {" "}
              <b>North West (NW) </b> Northwest direction is considered
              auspicious for business, friendship and support. The element for
              this zone is Air, colour is White, metal is Iron and shape is
              Circle.
            </li>
            <li>
              {" "}
              <b>North North West (NNW)</b> This is a zone of attraction and
              creativity. The element for this zone is Water, colour is blue,
              metal is Aluminium and shape is wavy.{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row align-center">
        <div className="col-lg-6">
          <img
            src="assets/img/34gates.jpg"
            style={{ borderRadius: "20%" }}
            alt="img"
          />
        </div>
        <div className="col-lg-6 text-center text-md-left">
          <div className="divider-60 divider-lg-0 " />
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>32 ENTRANCE OF VASTU</span>
          </h3>
          <div className="divider-35" />
          <p>
            The entrance gate of the house is the most important element not to
            be ignored in house Vastu. The main entrance to a house is not only
            the entry point for the people, but also for the cosmic energy.
            Hence, the correct positioning of the main gate according to the
            Vastu principles creates harmony between the house and the universe.
          </p>
          <p>
            There are 32 possible entrances according to Vastu shastra. After
            dividing a circle of 360°, you will get 32 entrances each covering
            11.25°. Every cardinal direction (North, East, South, and West) is
            divided into 8 possible entrances. For instance, 8 entrances for
            North (N1 to N8), 8 entrances for East (E1 to E8), 8 entrances for
            South (S1 to S8), 8 entrances for West (W1 to W8).
          </p>
        </div>
      </div>
    </div>
  </section>
  <Sev/>
</>


        <Footer/>
        </div>
        </div>
    </>
    )}