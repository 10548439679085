import React from "react";
import Header from "./header";
import Footer from "./footer";
export default function Contact() {
    return (
    <>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Improve Your Life with Amarpreet Mutreja</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active">Contact to SVS</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="ds bs s-py-40 s-py-xl-150">
    <div className="container">
      <div className="row">
        <div className="col-sm-4 " data-animation="pullDown">
          <div className="icon-box text-center">
            <a
                className="fs-24 links-maincolor-hover font-2"
                href="tel:+61422621093"
              >
            <div className="icon-styled color-main fs-56 mb-20">
              <i className="fa fa-phone" />
            </div>
            </a>
            <p>
              <strong>Phone:</strong>{" "}
              <a
                className="fs-24 links-maincolor-hover font-2"
                href="tel:+61422621093"
              >
                <mark>+61</mark>422621093
              </a>{" "}
              <br />
              <strong>WhatsApp:</strong>{" "}
              <a
                href="https://api.whatsapp.com/send/?phone=61422621093&text=We%20find%20your%20number%20from%20SRI%20VAST%20SOLUTIONS%20WEBSITE.&app_absent=0"
                className="fab fa-whatsapp"
                title="WhatsApp"
                target="_blank"
                rel="noreferrer"
              >
                <mark>+61</mark>422621093
              </a>
            </p>
          </div>
          <div className="divider-30 divider-lg-0" />
        </div>
        <div className="col-sm-4 " data-animation="pullDown">
          <div className="icon-box text-center">
            <div className="icon-styled color-main fs-56 mb-20">
              <i className="fa fa-map-marker" />
            </div>
            <p>
              Wollert <br />
              Melbourne, Victoria, Australia- 3750
            </p>
          </div>
          <div className="divider-30 divider-lg-0" />
        </div>
        <div className="col-sm-4 " data-animation="pullDown">
          <div className="icon-box text-center">
            <div className="icon-styled color-main fs-56 mb-20">
              <i className="fa fa-envelope" />
            </div>
            <p>
              <a href="mailto:srivastusolution@gmail.com">
              srivastusolution@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="ds ms page_map">
    <div>
      <iframe
        width="90%"
        height={440}
        title="Map"
        src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Wollert%20Melbourne,%20Victoria,%20Australia-%203750+(Sri%20Vastu%20Solutions)&t=&z=14&ie=UTF8&iwloc=B&output=embed"
      />
    </div>
    <div className="divider-90 divider-xl-0" />
  </section>
</>


        <Footer/>
        </div>
        </div>
    </>
    )}