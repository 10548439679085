import React from "react";
import Header from "./header";
import Footer from "./footer";
export default function Vastutips() {
    return (
    <>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Vastu Typs</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item active">Vastu Typs</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-pt-90 s-pt-xl-145 s-pb-60 s-pb-xl-115 container-px-30">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="row isotope-wrapper masonry-layout c-mb-30">
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs1.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>Location of entrance in a home has its own effect on the lives of the residents when it is located in between west and north-west directions, it leads to depression. Vastu suggests painting it in white color to overcome its bad effects.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs2.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>Keep the toilet seat covered and the toilet door closed so the negative energies don’t spread around the house.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs3.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>A silver ganesha idol is supposed to bring fame and publicity for you. If you have any silver ganesha idol in your collection, place it in the southeast, west or northwest direction. according to vastu, never place these silver idols in south or southwest direction.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs4.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>According to traditional feng shui, the lucky #bamboo #plant is used to attract health, happiness, love and abundance.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs6.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>Students should face EAST or NORTH direction while studying in order to memorize quickly and accelerate retention.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs7.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>Sharp edged table should be #avoided in Conference or Meeting Room.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs8.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>Placing a Buddha Statue in North  direction of your #house or office to attract positive energy and peace of mind.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs9.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>The wall colors should be light and soothing and the ambience appealing—think pink or peach color in the southWest direction.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs10.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>The exact position of the #bed in the master bedroom should be either in the south region or south-west, but never in between the two, because that can lead to marital issues.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs11.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>Clutter isn't just a physical Hindrance, it can actually cause Stress in your relationship, Create Health Challenges and Block your ability TO think and Act clearly.</p>
								</div> */}
              </div>
            </div>
            <div className="col-xl-4 col-sm-6">
              <div className="vertical-item  box-shadow bs ds bordered content-padding">
                <div className="item-media text-center">
                  <img
                    src="assets/img/typs/svs12.jpg"
                    alt="img"
                  />
                </div>
                {/* <div class="item-content">
									<p>For a sweet Relationship between Father and SON, North East direction should not have Kitchen, Toilet or Store Room. Also this direction should not be elevated otherwise, the two would Accuse and Blame Each Other Unnecessarily.</p>
								</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>


        <Footer/>
        </div>
        </div>
    </>
    )}