import React from "react";
export default function Footer() {
    return (
                <>
                <footer
                    id="contact"
                    className="page_footer ds ms s-py-50 s-pb-lg-60 s-pt-xl-150 s-pb-xl-120"
                >
                    <div className="container">
                    <div className="row align-center">
                        <div
                        className="col-xl-5 col-lg-5 text-center text-lg-left "
                        data-animation="fadeInUp"
                        >
                        <a href="/" className="logo">
                            <img src="assets/images/logo.png" alt="img" />
                            <span className="logo-text color-darkgrey" style={{"letterSpacing": 0}}>SRI Vastu Solutions</span>
                        </a>
                        
                        <br />
                        <div className="widget widget_text">
                            <p>
                            At Sri Vastu Solutions we provide solutions for home, work place , new construction site from floor plan to build for all types of  house and businesses. We serve all across the globe . Please Speak to our team to seek guidance for a brighter future.
                            </p>
                            <p className="social-icons text-center">
                            {/* <a
                                className="fa fa-phone"
                                title="instagram"
                                target="_blank"
                                href="tel:+61425404494"
                                rel="noreferrer"
                            /> */}
                            <a
                                className="fa fa-phone"
                                title="instagram"
                                target="_blank"
                                href="tel:+61422621093"
                                rel="noreferrer"
                            />
                            <a
                                href="https://www.facebook.com/srivastusolution"
                                className="fa fa-facebook"
                                title="facebook"
                                target="_blank"
                                rel="noreferrer"
                            />
                            <a
                                href="https://twitter.com/SriVastu_AP"
                                className="fa fa-twitter"
                                title="twitter"
                                target="_blank"
                                rel="noreferrer"
                            />
                            <a
                                href="https://www.instagram.com/srivastusolutions"
                                className="fa fa-instagram"
                                title="instagram"
                                target="_blank"
                                rel="noreferrer"
                            />
                            {/* <a
                                href="https://www.youtube.com/"
                                className="fa fa-youtube-play"
                                title="youtube"
                                target="_blank"
                                rel="noreferrer"
                            /> */}
                            <a
                                href="https://api.whatsapp.com/send/?phone=61422621093&text=We%20find%20your%20number%20from%20SRI%20VAST%20SOLUTIONS%20WEBSITE.&app_absent=0"
                                className="fab fa-whatsapp"
                                title="WhatsApp"
                                target="_blank"
                                rel="noreferrer"
                            />
                            </p>
                        </div>
                        </div>
                        <div
                        className="col-xl-2 col-lg-2 align-self-start text-center text-lg-left "
                        data-animation="fadeInUp"
                        >
                        <div className="widget widget_nav_menu">
                            <h3>Services</h3>
                            <ul className="menu">
                            <li className="menu-item">
                                <a href="/vastu">Vastu Shastra</a>
                            </li>
                            <li className="menu-item">
                                <a href="/astrology">Astrology</a>
                            </li>
                            <li className="menu-item">
                                <a href="/numerology">Numerology </a>
                            </li>
                            <li className="menu-item">
                                <a href="/fengshui">Feng-shui </a>
                            </li>
                            </ul>
                        </div>
                        <div className="divider-60 divider-lg-0" />
                        </div>
                        <div
                        className="col-xl-2 col-lg-2 align-self-start text-center text-lg-left "
                        data-animation="fadeInUp"
                        >
                        <div className="widget widget_nav_menu">
                            <h3>About Me</h3>
                            <ul className="menu">
                            <li className="menu-item">
                                <a href="/about">About US</a>
                            </li>
                            <li className="menu-item">
                                <a href="/services">Services</a>
                            </li>
                            <li className="menu-item">
                                <a href="/contact">Contact Us</a>
                            </li>
                            </ul>
                        </div>
                        <div className="divider-60 divider-lg-0" />
                        </div>
                        <div
                        className="col-xl-3 col-lg-3 align-self-start text-center text-lg-left "
                        data-animation="fadeInUp"
                        >
                        <div className="widget widget_text">
                            <h3>Contact Info</h3>
                            <p>
                            Wollert <br />
                            Melbourne, Victoria, Australia- 3750
                            </p>
                            <p>
                            Mon-Fri 9:00am -6:00pm <br />
                            Saturday- 10:00am- 6:00 pm
                            </p>
                            <p>
                            {/* <a href="mailto:info@srivastusolution.com.au">
                                info@srivastusolutions.com.au
                            </a>
                            <br/> */}
                            <a href="mailto:srivastusolution@gmail.com">
                            srivastusolution@gmail.com
                            </a>
                            </p>
                            <p>
                            {/* <a
                                className="fs-24 links-maincolor-hover font-2"
                                href="tel:+61425404494"
                            >
                                <mark>+61</mark>425 404 494
                            </a><br/> */}
                            <a
                                className="fs-24 links-maincolor-hover font-2"
                                href="tel:+61422621093"
                            >
                                
                                <mark>+61</mark>422 621 093
                            </a>
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </footer>
                <section className="page_copyright ds ms s-py-25">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                        <p>
                            © Copyright <span className="copyright_year">2024-2025</span> All
                            Rights Reserved
                        </p>
                        </div>
                    </div>
                    </div>
                </section>
                {/* <a className="tocall btn btn-medium" target="_blank" href="tel:+61422621093" rel="noreferrer" style={{display: "inline", cursor : "pointer"}}>
        
        Call now
        </a> */}
                </>
    )
}