import React from "react";

export default function Sev() {
    return (
        <>
            {/*Section Service*/}
  {/* <section
    id="services"
    className="ds section-service s-overlay mobile-overlay s-py-40 s-py-xl-150 container-px-xl-0"
  >
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-5" />
        <div className="col-xl-3 col-lg-6">
          <p className="text-white text-center text-md-left fs-20 line-right mb-0">
            <span>Services</span>
          </p>
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span>
              Let’s Improve <br />
              Our Life
            </span>
          </h3>
        </div>
        <div className="col-xl-3 col-lg-6 text-center text-md-left">
          <p className="text-center text-md-left  mt-4 mt-lg-0">
            <span>
            At Sri Vastu Solutions we can provide the best <br/> services for Vastu , Astrology, Numerology and Feng- Shui.
            </span>
          </p>
          <div className="divider-35" />
          <a
            href="/contact"
            className="btn btn-outline-maincolor btn-medium"
          >
            Book Consultancy
          </a>
        </div>
        <div className="col-xl-1" />
        <div className="col-xl-4" />
        <div className="col-xl-8">
          <div className="big-width">
            <div className="divider-55" />
            <div
              className="owl-carousel "
              data-responsive-lg={4}
              data-responsive-md={3}
              data-responsive-sm={2}
              data-responsive-xs={1}
              data-nav="false"
              data-margin={30}
              data-loop="true"
              data-autoplay="true"
            >
              <div className="vertical-item service-layout-2 content-absolute content-padding">
                <div className="item-media">
                  <img
                    src=" assets/images/services/img_12.jpg"
                    alt="img"
                    style={{ height: 450 }}
                  />
                  <div className="media-links">
                    <a className="abs-link" title="" href="#" />
                  </div>
                </div>
                <div className="item-content overflow-hidden">
                  <div className="d-flex align-center flex-wrap">
                    <h5 className="my-0 links-maincolor2 mr-auto pr-2">
                      <a href="/vastu">Vastu Shastra</a>
                    </h5>
                    <a href="<?=/vastu" className="font-2">
                      Read Now{" "}
                      <i className="fa fa-caret-right color-main ml-2" />
                    </a>
                  </div>
                  <p>
                    We provide best solutions for vastu of your home, office,
                    workspace, school, etc. for any enquiry please contact us.
                  </p>
                </div>
              </div>
              <div className="vertical-item service-layout-2 content-absolute box-shadow  content-padding">
                <div className="item-media">
                  <img
                    src=" assets/images/services/img_10.jpg"
                    alt="img"
                    style={{ height: 450 }}
                  />
                  <div className="media-links">
                    <a className="abs-link" title="" href="#" />
                  </div>
                </div>
                <div className="item-content overflow-hidden">
                  <div className="d-flex align-center flex-wrap">
                    <h5 className="my-0 links-maincolor2 mr-auto pr-2">
                      <a href="/astrology">Astrology</a>
                    </h5>
                    <a
                      href="/astrology"
                      className="font-2"
                    >
                      Read Now{" "}
                      <i className="fa fa-caret-right color-main ml-2" />
                    </a>
                  </div>
                  <p>
                    Astrology works on rotation of nine planets and 27
                    Nakshtras. At the birth time of person where ever these
                    planets are rotating becomes person's birth chart.
                  </p>
                </div>
              </div>
              <div className="vertical-item service-layout-2 content-absolute box-shadow  content-padding">
                <div className="item-media">
                  <img
                    src=" assets/images/services/img_14.jpg"
                    alt="img"
                    style={{ height: 450 }}
                  />
                  <div className="media-links">
                    <a className="abs-link" title="" href="#" />
                  </div>
                </div>
                <div className="item-content overflow-hidden">
                  <div className="d-flex align-center flex-wrap">
                    <h5 className="my-0 links-maincolor2 mr-auto pr-2">
                      <a href="/numerology">
                        Numerology{" "}
                      </a>
                    </h5>
                    <a
                      href="/numerology"
                      className="font-2"
                    >
                      Read Now{" "}
                      <i className="fa fa-caret-right color-main ml-2" />
                    </a>
                  </div>
                  <p>
                    These calculations can go into many layers of depth with
                    different numbers and combinations of numbers carrying
                    various meanings.
                  </p>
                </div>
              </div>
              <div className="vertical-item service-layout-2 content-absolute box-shadow  content-padding">
                <div className="item-media">
                  <img
                    src=" assets/images/services/img_15.jpg"
                    alt="img"
                    style={{ height: 450 }}
                  />
                  <div className="media-links">
                    <a className="abs-link" title="" href="#" />
                  </div>
                </div>
                <div className="item-content overflow-hidden">
                  <div className="d-flex align-center flex-wrap">
                    <h5 className="my-0 links-maincolor2 mr-auto pr-2">
                      <a href="/fengshui">Feng-shui</a>
                    </h5>
                    <a
                      href="/fengshui"
                      className="font-2"
                    >
                      Read Now{" "}
                      <i className="fa fa-caret-right color-main ml-2" />
                    </a>
                  </div>
                  <p>
                    Feng shui is the art or science of arranging the elements of
                    a space in order to allow the maximum possible flow of
                    positive chi.
                  </p>
                </div>
              </div>
            </div>
            <div className="divider-35" />
            <div className="owl-custom-nav romb">
              <a href="#" className="owl-prev">
                <i className="ico ico-right-arrow" />
              </a>
              <a href="#" className="owl-next">
                <i className="ico ico-right-arrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/* Section Faqs */}
  <section className="  section-service">
	<div className="container">
    <div className="row">
        
        <div className="col-xl-5 col-lg-3">
          <p className="text-white text-center text-md-left fs-20 line-right mb-0">
            <span>Services</span>
          </p>
          <h3 className="special-heading mt-2 fs-60 text-center text-md-left">
            <span style={{"color" : "white"}}>
              Let’s Improve <br />
              Our Life
            </span>
          </h3>
        </div>
        <div className="col-xl-7 col-lg-6 text-center text-md-left">
          <p className="text-center text-md-left  mt-4 mt-lg-0">
            <span style={{"color" : "white"}}>
            At Sri Vastu Solutions we can provide the best <br/> services for Vastu , Astrology, Numerology and Feng- Shui.
            </span>
          </p>
          <div className="divider-35" />
          <a
            href="/contact"
            className="btn btn-outline-maincolor btn-medium" style={{"color" : "white"}}
          >
            Book Consultancy
          </a>
        </div>
        {/* <div className="col-xl-6 col-lg-5"/> */}

    </div>
		<div className="row">
      
      <div className="col-xl-12 row">
          <div className="col-xl-3 col-lg-3 mt-2">
          <div className="vertical-item bg-darkblue text-center service-layout-2 box-shadow  content-padding">
                  <div className="item-media">
                    <img
                      src=" assets/images/services/img_12.jpg"
                      alt="img"
                      style={{ height: 350 }}
                    />
                    <div className="media-links">
                      <a className="abs-link" title="" href="#" />
                    </div>
                  </div>
                  <div className="item-content overflow-hidden">
                    <div className="d-flex align-center flex-wrap">
                      <h5 className="my-0 links-maincolor2 mr-auto pr-2">
                        <a href="/vastu">Vastu Shastra</a>
                      </h5>
                      <a href="/vastu" className="font-2">
                        Read Now{" "}
                        <i className="fa fa-caret-right color-main ml-2" />
                      </a>
                    </div>
                    <p>
                      We provide best solutions for vastu of your home, office,
                      workspace, school, etc. for any enquiry please contact us.
                    </p>
                  </div>
                </div>
          </div>
          <div className="col-xl-3 col-lg-3 mt-2">
                <div className="vertical-item bg-darkblue text-center service-layout-2 box-shadow  content-padding">
                  <div className="item-media">
                    <img
                      src=" assets/images/services/img_10.jpg"
                      alt="img"
                      style={{ height: 350 }}
                    />
                    <div className="media-links">
                      <a className="abs-link" title="" href="#" />
                    </div>
                  </div>
                  <div className="item-content overflow-hidden">
                    <div className="d-flex align-center flex-wrap">
                      <h5 className="my-0 links-maincolor2 mr-auto pr-2">
                        <a href="/astrology">Astrology</a>
                      </h5>
                      <a
                        href="/astrology"
                        className="font-2"
                      >
                        Read Now{" "}
                        <i className="fa fa-caret-right color-main ml-2" />
                      </a>
                    </div>
                    <p>
                      Astrology works on rotation of nine planets and 27
                      Nakshtras. At the birth time of person where ever these
                      planets are rotating becomes person's birth chart.
                    </p>
                  </div>
                </div>
          </div>
          <div className="col-xl-3 col-lg-6 mt-2">
                <div className="vertical-item bg-darkblue text-center service-layout-2 box-shadow  content-padding">
                  <div className="item-media">
                    <img
                      src=" assets/images/services/img_14.jpg"
                      alt="img"
                      style={{ height: 350 }}
                    />
                    <div className="media-links">
                      <a className="abs-link" title="" href="#" />
                    </div>
                  </div>
                  <div className="item-content overflow-hidden">
                    <div className="d-flex align-center flex-wrap">
                      <h5 className="my-0 links-maincolor2 mr-auto pr-2">
                        <a href="/numerology">
                          Numerology{" "}
                        </a>
                      </h5>
                      <a
                        href="/numerology"
                        className="font-2"
                      >
                        Read Now{" "}
                        <i className="fa fa-caret-right color-main ml-2" />
                      </a>
                    </div>
                    <p>
                      These calculations can go into many layers of depth with
                      different numbers and combinations of numbers carrying
                      various meanings.
                    </p>
                  </div>
                </div>
          </div>
          <div className="col-xl-3 col-lg-6 mt-2">
                <div className="vertical-item bg-darkblue text-center service-layout-2 box-shadow  content-padding">
                  <div className="item-media">
                    <img
                      src=" assets/images/services/img_15.jpg"
                      alt="img"
                      style={{ height: 350 }}
                    />
                    <div className="media-links">
                      <a className="abs-link" title="" href="#" />
                    </div>
                  </div>
                  <div className="item-content overflow-hidden">
                    <div className="d-flex align-center flex-wrap">
                      <h5 className="my-0 links-maincolor2 mr-auto pr-2">
                        <a href="/fengshui">Feng-shui</a>
                      </h5>
                      <a
                        href="/fengshui"
                        className="font-2"
                      >
                        Read Now{" "}
                        <i className="fa fa-caret-right color-main ml-2" />
                      </a>
                    </div>
                    <p>
                      Feng shui is the art or science of arranging the elements of
                      a space in order to allow the maximum possible flow of
                      positive chi.
                    </p>
                  </div>
                </div>
          </div>
      </div>
      <div className="col-xl-6"/>
		</div>
	</div>
</section>

        </>
    )
}