import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from 'react-helmet';
import Sev from "./sev";
export default function Numerology() {
    return (
    <>
    <Helmet>
        <title>Numerology and Vastu Shastra | Numerology Consultant Somerton, Thomastown</title>
        <meta name="description" content="Experience prosperity and clarity through the combined power of numerology and vastu shastra. Our numerology consultants offer the best solution for a harmonious living." />
    </Helmet>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Vastu Services</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/services">Services</a>
            </li>
            <li className="breadcrumb-item active">Numerology Services</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="vertical-item bg-darkblue text-center content-padding padding-big">
            <div className="item-media">
              <img
                src="assets/img/numerology.jpg"
                alt="Sri Vastu Solutions"
              />
            </div>
            <div className="item-content max-content">
              <h2>Numerology Services</h2>
              <p>
                Numerology is an ancient study that draws meaning from different
                numbers, number combinations, letters, and symbols in your life.
                This art can help us tap into the underlying patterns of the
                universe and reveal new truths about who we are.
              </p>
              <p>
                Your Life-Path number is probably the most influential
                numerological aspect to be considered. This number is determined
                by your birth date and represents who you are at this time. It
                indicates specific traits that are present and will likely be
                active and influential throughout your lifetime.
              </p>
              <p>
                Traditionally, when calculating our life path number, or any
                numbers in our numerological portrait, we break it down into a
                single-digit number. However, there are two exceptions to this
                rule: 11 and 22. These special numbers are considered “master
                numbers”—and as such, possess more potential than others.
                <br />
                Why are they so special? Well, not only do they have two
                identical digits, but they have powerful numbers to be doubled.
                The number “One” is the leader of numerology and “Two” is the
                harmonizer. Having these identical digits together create a lot
                of energy and power—in both positive and negative ways.
                <br />
                It is important to note that master numbers are usually found in
                our life path number. If they show up as part of your
                numerological portrait, they should be added to a single-digit
                number.
              </p>
            </div>
          </div>
          <div className="share_buttons social_part">
            <a
              href="https://www.facebook.com/srivastusolution"
              className=" color-bg-icon fa fa-facebook"
              title="facebook"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://twitter.com/SriVastu_AP"
              className="color-bg-icon fa fa-twitter"
              title="twitter"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.instagram.com/srivastusolutions"
              className="color-bg-icon fa fa-instagram"
              title="instagram"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.youtube.com/"
              className="color-bg-icon fa fa-youtube-play"
              title="youtube"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
        {/* .col-* */}
      </div>
    </div>
  </section>
  <Sev/>
</>


        <Footer/>
        </div>
        </div>
    </>
    )}