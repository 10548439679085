import React from "react";
import Header from "./header";
import Footer from "./footer";
import Sev from "./sev";
export default function Fengshui() {
    return (
    <>
        <div id="canvas">
		<div id="box_wrapper">
        <Header/>
        <>
  {/*Section Top*/}
  <section className="page_title ds s-overlay s-parallax s-pt-130 s-pt-xl-150 s-pb-65">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h1>Vastu Services</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/services">Services</a>
            </li>
            <li className="breadcrumb-item active">Feng-Shui Services</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section className="ds s-py-40 s-py-xl-150 c-gutter-60">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="vertical-item bg-darkblue text-center content-padding padding-big">
            <div className="item-media">
              <img
                src="assets/img/feng.jpg"
                alt="Sri Vastu Solutions"
                style={{ borderRadius: "20%" }}
              />
            </div>
            <div className="item-content max-content">
              <h2>Feng-Shui Services</h2>
              <p>
                Feng shui is one of the Five Arts of Chinese Metaphysics,
                classified as physiognomy (observation of appearances through
                formulas and calculations). The feng shui practice discusses
                architecture in terms of "invisible forces" that bind the
                universe, earth, and humanity together, known as qi.
                <br />
                Historically, feng shui was widely used to orient
                buildings—often spiritually significant structures such as
                tombs, but also dwellings and other structures—in an auspicious
                manner. Depending on the particular style of feng shui being
                used, an auspicious site could be determined by reference to
                local features such as bodies of water, stars, or the compass.
              </p>
              <p>
                Traditional feng shui is an ancient system based upon the
                observation of heavenly time and earthly space. Literature, as
                well as archaeological evidence, provide some idea of the
                origins and nature of feng shui techniques. Aside from books,
                there is also a strong oral history. In many cases, masters have
                passed on their techniques only to selected students or
                relatives.[46] Modern practitioners of feng shui draw from
                several branches in their own practices.
              </p>
              <p>
                Believers use it for healing purposes though there is no
                empirical evidence that it is in any way effective, to guide
                their businesses, or create a peaceful atmosphere in their
                homes. In particular, they use feng shui in the bedroom, where a
                number of techniques involving colors and arrangement achieve
                comfort and peaceful sleep.[citation needed] Some users of feng
                shui may be trying to gain a sense of security or control, such
                as by choosing auspicious numbers for their phones or favorable
                house locations. Their motivation is similar to the reasons that
                some people consult fortune-tellers.
              </p>
              <p>
                Whether it’s a home office or an outside workspace, you probably
                spend a lot of hours in your office. Feng shui proponents
                believe that employing its principles in your office can bring
                about productivity and success. An office that’s inviting,
                organized, and aesthetically appealing can make work more
                enjoyable. While there are anecdotal stories of success as a
                result of using feng shui, the outcomes of the practice have not
                been scientifically evaluated.
              </p>
              <h4>The 5 elements of feng shui</h4>
              <p>
                In feng shui, there are five elements that attract energy and
                need to be balanced. These include:
              </p>
              <ul style={{ textAlign: "left" }}>
                <li>
                  <b>Wood</b> This element channels creativity and growth.
                  Trees, plants, or green objects can represent wood.
                </li>
                <li>
                  <b>Fire</b> This is the most powerful element. It creates
                  passion, energy, expansion, boldness, and transformation.
                  Candles or the color red can bring the fire element into a
                  space.
                </li>
                <li>
                  <b>Water</b> This element is linked to emotion and
                  inspiration. Water features or blue items can represent this
                  element.
                </li>
                <li>
                  <b>Earth</b> The earth element signifies stability and
                  strength. Incorporate the earth element with rocks, carpets,
                  old books, or things that are brown or tan in color.
                </li>
                <li>
                  <b>Metal</b> Metal unites all the elements, while offering
                  focus and order. Use objects that are metal or white, silver,
                  or grey in color.
                </li>
              </ul>
              <p />
            </div>
          </div>
          <div className="share_buttons social_part">
            <a
              href="https://www.facebook.com/srivastusolution"
              className=" color-bg-icon fa fa-facebook"
              title="facebook"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://twitter.com/SriVastu_AP"
              className="color-bg-icon fa fa-twitter"
              title="twitter"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.instagram.com/srivastusolutions"
              className="color-bg-icon fa fa-instagram"
              title="instagram"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.youtube.com/"
              className="color-bg-icon fa fa-youtube-play"
              title="youtube"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
        {/* .col-* */}
      </div>
    </div>
  </section>
  <Sev/>
</>


        <Footer/>
        </div>
        </div>
    </>
    )}